import React from "react";
import { v4 as uuidv4 } from "uuid";
import Job from "../../assets/images/job.jpg";
import Icon_Equipment from "../../assets/images/why-choose-us/equipment.png";
import Icon_Estimates from "../../assets/images/why-choose-us/estimates.png";
import Icon_Irreigation from "../../assets/images/why-choose-us/irreigation.png";
import Icon_Work from "../../assets/images/why-choose-us/work.png";

const WhyChooseUsOneData = [
  {
    id: uuidv4(),
    heading: "Free Estimates",
    description:
      "A Free Estimate provides a cost free evaluation of your project's needs, offering insights and potential costs without any charge.",
    img: Icon_Estimates,
  },
  {
    id: uuidv4(),
    heading: "Modern Equipment",
    description:
      "Modern Equipment denotes our state-of-the-art tools, optimized for efficiency, precision, and superior service delivery.",
    img: Icon_Equipment,
  },
  {
    id: uuidv4(),
    heading: "Quality Work",
    description:
      "Our work embodies excellence, driven by meticulous attention to detail, expertise, and unwavering commitment to quality.",
    img: Icon_Work,
  },
  {
    id: uuidv4(),
    heading: "Watering & Irrigation",
    description:
      "Our meticulous watering and irrigation methods promise vibrant landscapes, ensuring sustained growth and flourishing beauty.",
    img: Icon_Irreigation,
  },
];

const WhyChooseUsOneItem = ({ heading, description, img }) => {
  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-6">
        <div className="single-choose-us">
          <div className="single-choose-us-content bb-1 b-color-2">
            <h3>{heading}</h3>
            <p>{description}</p>
          </div>
          <div className="single-choose-us-icon">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

const WhyChoseUsOne = () => {
  return (
    <>
      <div
        className="height-592 bg-property pt-110 pb-110"
        data-overlay="theme-1"
        data-opacity="1"
        style={{ backgroundImage: `url(${Job})` }}
      >
        <div className="container">
          <div className="row position-relative">
            <div className="col-xl-12">
              <div className="section-title text-center mb-70">
                <span>Why Choose US</span>
                <h2 className="white-color">
                  Experience the Ultimate Level of <br /> Landscape Power
                </h2>
              </div>
            </div>
          </div>
          <div className="row position-relative">
            {WhyChooseUsOneData.map((data) => (
              <WhyChooseUsOneItem
                key={data.id}
                heading={data.heading}
                description={data.description}
                img={data.img}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChoseUsOne;
