const contactConfig = {
  YOUR_EMAIL: "andreysprolandscaping@gmail.com",
  description: "Looking forward to hearing from you!",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_bfubwuq",
  YOUR_TEMPLATE_ID: "template_0si7pol",
  YOUR_USER_ID: "4aPK-D46Ugv5rD2xr",
};

export { contactConfig };
